<template>
  <div class="paperMonitor">
    <ExamDetailsHeader
      ref="ExamDetailsHeader"
      :router-url="
        $route.query.routerName ? $route.query.routerName : '/exam/unionExam'
      "
      :query="{ examId: $route.query.examId }"
    ></ExamDetailsHeader>
    <div
      class="monitor-box"
      :class="{
        allviewbox:
          $route.query.type == 'teacher' || $route.query.from == 'examList',
      }"
    >
      <div class="tab-box">
        <div
          v-if="addMonitorList.filter((item) => item.type).length > 0"
          class="tab-item-box"
        >
          <div class="tab-item-header">进度监控</div>

          <template v-for="(v, i) in addMonitorList">
            <div
              v-if="v.type"
              :key="i"
              class="tab-item"
              :class="{
                'tab-on':
                  v.value == $route.name || v.value == $route.meta.fName,
              }"
              @click="toLink(v)"
            >
              {{ v.name }}
            </div>
          </template>

          <div
            v-if="userInfo.roleId != 5 || leaderType"
            class="tab-item tab-err"
            :class="{
              'tab-on':
                'unionMarkingTheAlarm' == $route.name ||
                $route.meta.fName == 'unionMarkingTheAlarm',
            }"
            @click="
              toLink({
                value: 'unionMarkingTheAlarm',
              })
            "
          >
            阅卷报警（{{ errNumber }}）
          </div>
        </div>
        <div
          v-if="qualityMonitorList.filter((item) => item.type).length > 0"
          class="tab-item-box"
          style="margin-top: 8px"
        >
          <div class="tab-item-header">质量监控</div>

          <template v-for="(v, i) in qualityMonitorList">
            <div
              v-if="v.type"
              :key="i"
              class="tab-item"
              :class="{ 'tab-on': v.value == $route.name }"
              @click="toLink(v)"
            >
              {{ v.name }}
            </div>
          </template>
        </div>
      </div>
      <div class="views-box">
        <router-view :key="isRouterAlive" />
      </div>
    </div>
  </div>
</template>

<script>
// const isUnion = 0;

// import ExamDetailsHeader from "@/views/exam/component/ExamDetailsHeader";
import ExamDetailsHeader from "@/components/examinationHeader";
import { getObj } from "@/core/api/exam/exam";
import {
  markabnormolCount,
  teacherPermissionV2,
  leaderPermission,
} from "@/core/api/exam/paperMonitor";
import { getStore, removeStore } from "@/core/util/store";
import { schoollistbyexamid } from "@/core/api/exam/exam";

export default {
  name: "PaperMonitor",
  components: {
    ExamDetailsHeader,
  },
  data() {
    return {
      errNumber: 0,
      isRouterAlive: 1,
      leaderType: false,
      refTime: "",
      teacherRoleArr: [],
      addMonitorList: [],
      qualityMonitorList: [],
      examInformation: {
        name: "",
      },
      subjectName: "",
      userInfo: {},
      teacherRoleList: [],
      paperList: [],
      showTab: true,
      menuList: [],
      indexName: "",
    };
  },
  async created() {
    this.getNewTime();
    await this.getExamInfo();
    this.markabnormolCount();
    this.setKey();

    // 如果是教师  获取权限列表
    if (this.userInfo.roleId == 5) {
      await this.getleaderPermission();
      if (this.leaderType) {
        //
      } else {
        this.teacherPermissionV2();
      }
    }
    if (
      this.$route.query.routerName == "examlist" &&
      this.$route.query.examType == 1
    ) {
      this.getExamSchools();
    }
  },

  destroyed() {
    clearTimeout(this.timer);
  },
  methods: {
    async getleaderPermission() {
      let data = {
        examId: this.$route.query.examId,
      };
      await leaderPermission(data)
        .then((res) => {
          if (res.data.data) {
            if (res.data.data.subjectIdList) {
              let subjectIdList = res.data.data.subjectIdList.join(",");
              let subjectId = this.$route.query.subjectId.toString();
              if (subjectIdList.indexOf(subjectId) != -1) {
                this.leaderType = res.data.data.ifHasLeaderPermission;
              } else {
                this.leaderType = 0;
              }
            }
          }

          if (this.leaderType) {
            let subjectIdList = res.data.data.subjectIdList;
            subjectIdList.map((item) => {
              this.teacherRoleList.push({
                teacherIds: this.userInfo.teacherId.toString(),
                subjectType: 8,
                subjectId: item,
              });
              this.teacherRoleList.push({
                teacherIds: this.userInfo.teacherId.toString(),
                subjectType: 9,
                subjectId: item,
              });
            });

            this.$store.commit("SET_TEACHER_ROLE_LIST", this.teacherRoleList);
            this.setRole();
          }
        })
        .catch(() => {});
    },
    check(item) {
      // console.log(item);
      let { url } = item;
      let query = {};
      let { examId, level, examType } = this.examInformation;
      let { paperId, subjectId, markType } = this.indexPaper;
      if (url == "ExamTemplate") {
        query = {
          examId: examId,
          subjectId: subjectId,
        };
      } else if (url == "ExamPaperAnswer") {
        query = {
          examId: examId,
          paperId: paperId,
        };
      } else if (url == "scanSheet") {
        query = {
          examId: examId,
          examPaperId: paperId,
          level: level,
          examType: examType,
        };
      } else if (url == "ExamCorrectTask") {
        let data = {
          paperId: paperId,
          examId: examId,
          examType: examType,
        };
        if (markType == 2) {
          url = "ExamClassReadCorrectTask";
        }
        // console.log(data);
        query = data;
      }
      this.$router.push({
        name: url,
        query: query,
      });
    },

    async getExamSchools() {
      const res = await schoollistbyexamid({
        examId: this.$route.query.examId,
      });

      let schoolList = [{ schoolName: "全部学校", schoolId: "-1" }].concat(
        res.data.data
      );
      this.$store.commit("SET_SCHOOL_LIST", schoolList);
      // this.schoolId = "-1";
    },
    setKey() {
      // let qualityMonitorList = [
      //   {
      //     name: "质量列表",
      //     value: "GradeQuality",
      //     type: true,
      //     key: 9,
      //   },
      //   {
      //     name: "阅卷重评",
      //     value: "MarkingReappraisal",
      //     type: true,
      //     key: 9,
      //   },
      // ];
      let qualityMonitorList = [
        {
          name: "质量列表",
          value: "GradeQuality",
          type: true,
          key: 9,
        },
        {
          name: "评分曲线",
          value: "GradingCurve",
          type: true,
          key: 9,
        },
        {
          name: "多评监控",
          value: "MultipleMonitoring",
          type: true,
          key: 9,
        },
        {
          name: "抽查阅卷",
          value: "SamplePaperInspection",
          type: true,
          key: 9,
        },

        // {
        //   name: "仲裁监控",
        //   value: "ArbitrationMonitoring",
        //   type: true,
        //   key: "9",
        // },
      ];

      if (this.userInfo.roleId != 5) {
        qualityMonitorList.push({
          name: "阅卷重评",
          value: "MarkingReappraisal",
          type: true,
          key: 9,
        });
      }
      const addMonitorList = [
        {
          name: "进度监控",
          value: "MarkingProgress",
          type: true,
          key: 8,
        },
        {
          name: "正在批阅的试题",
          value: "IsReading",
          type: true,
          key: 8,
        },
      ];
      this.addMonitorList = addMonitorList;
      this.qualityMonitorList = qualityMonitorList;

      this.qualityMonitorList.map((index) => {
        index.value = "union" + index.value;
      });
      this.addMonitorList.map((index) => {
        index.value = "union" + index.value;
      });
    },
    teacherPermissionV2() {
      let data = {
        examId: this.$route.query.examId,
      };
      teacherPermissionV2(data)
        .then((res) => {
          this.teacherRoleList = res.data.data;
          // 再判断下是不是有分组阅卷情况
          if (res.data.data && res.data.data.length == 0) {
            let indexPaper = this.paperList.filter(
              (item) => item.paperId == this.$route.query.examPaperId
            )[0];
            // console.log(indexPaper.ifProcessMonitor);
            if (indexPaper.ifProcessMonitor == 1) {
              this.teacherRoleList.push({
                teacherIds: this.userInfo.teacherId.toString(),
                subjectType: 8,
                subjectId: indexPaper.subjectId,
              });
              // this.teacherRoleList.push({
              //   teacherIds: this.userInfo.teacherId.toString(),
              //   subjectType: 9,
              //   subjectId: indexPaper.subjectId,
              // });
            }
          }

          // 缓存起教师情况下的接口
          this.$store.commit("SET_TEACHER_ROLE_LIST", this.teacherRoleList);
          this.setRole();
        })
        .catch(() => {
          this.setRole();
        });
    },
    setRole() {
      // let subjectId = val || this.$route.query.subjectId;
      // 老师的情况 根据后台返回的权限进行判断 显示哪一部分
      if (this.userInfo.roleId == 5) {
        let qualityArray = [];
        let monitorArray = [];
        let qualitySubjectIds = [];
        let monitorSubjectIds = [];
        // let teacherRoleList = []
        this.teacherRoleList.forEach((index) => {
          if (!index.teacherIds) {
            return;
          }
          let ids = index.teacherIds.split(",");
          ids.forEach((item) => {
            if (
              item == this.userInfo.teacherId &&
              index.subjectType == 9 &&
              index.subjectId == this.$route.query.subjectId
            ) {
              qualityArray.push(index.subjectType);
              qualitySubjectIds.push(index.subjectId);
            }
            if (
              item == this.userInfo.teacherId &&
              index.subjectType == 8 &&
              index.subjectId == this.$route.query.subjectId
            ) {
              monitorArray.push(index.subjectType);
              monitorSubjectIds.push(index.subjectId);
            }
          });
        });

        this.$store.commit("SET_MSUBJECTIDS", monitorSubjectIds);
        this.$store.commit("SET_QSUBJECTIDS", qualitySubjectIds);
        this.teacherRoleArr = monitorArray;

        this.qualityMonitorList.map((index) => {
          index.type = false;

          if (qualityArray.indexOf(index.key) != -1) {
            index.type = true;
          }
        });

        this.addMonitorList.map((index) => {
          index.type = false;
          // console.log(index.key);
          if (monitorArray.indexOf(index.key) != -1) {
            index.type = true;
          }
        });

        if (
          this.qualityMonitorList.filter((item) => item.type).length == 0 &&
          this.addMonitorList.filter((item) => item.type).length == 0 &&
          this.$route.query.type != "teacher"
        ) {
          if (this.$route.meta.linkKey) return;
          this.showTab = false;
          this.$message({
            message: "当前教师没有权限，即将返回考试列表！",
            type: "warning",
            duration: 1000,
          });
          // setTimeout(() => {
          //   this.goBack();
          // }, 1000);
        }
        // qualityMonitorList addMonitorList
      }
    },
    markabnormolCount(examId) {
      // console.log(this.$route);
      // console.log(this.timer);
      if (!examId && this.timer) {
        return;
      }
      if (!this.$route.query.routerName) {
        return;
      }
      let data = {
        examId: this.$route.query.examId,
      };
      markabnormolCount(data)
        .then((res) => {
          this.errNumber = res.data.data;
          this.timer = setTimeout(() => {
            this.markabnormolCount(this.$route.query.examId);
          }, 10000);
        })
        .catch(() => {
          // clearInterval(this.timer);
        });
    },
    toLink(v) {
      let data = JSON.parse(JSON.stringify(this.$route.query));
      delete data.groupId;
      removeStore({ name: "GradeQuality_info", type: 1 });
      let ifProcessMonitor = getStore({
        name: "ifProcessMonitor",
      });
      let url = v.value;
      // 添加判断
      if (ifProcessMonitor == 1 && v.value == "unionMarkingProgress") {
        url = "groupProgress";
      }
      this.$router.push({
        name: url,
        query: data,
      });
    },
    async getExamInfo() {
      if (!this.$route.query.examId) {
        return;
      }
      this.userInfo = getStore({ name: "userInfo" });
      await getObj(this.$route.query.examId).then((res) => {
        this.examInformation = res.data.data;

        this.paperList = res.data.data.paperList.map((item) => {
          if (item.statusSheet == 1 && item.sheetMarkType != 1) {
            item.label = item.subjectName;
            item.value = item.paperId;
            return item;
          } else {
            return null;
          }
        });
        this.paperList = this.paperList.filter((item) => item);
        this.$refs.ExamDetailsHeader.init(this.$route.query.examPaperId);
        this.indexPaperId = this.$route.query.examPaperId;
        this.subjectName = this.paperList.filter(
          (item) => item.paperId == this.$route.query.examPaperId
        )[0].subjectName;
      });
    },
    checkSubject(v) {
      let data = JSON.parse(JSON.stringify(this.$route.query));
      data.examPaperId = v.paperId;
      data.getType = v.getType;
      data.markType = v.markType;
      removeStore({ name: "GradeQuality_info", type: 1 });
      delete data.groupId;
      this.$router.push({
        query: data,
      });
      let indexPaper = this.paperList.filter(
        (item) => item.paperId == this.$route.query.examPaperId
      )[0].subjectName;
      this.subjectName = indexPaper.subjectName;
      this.indexPaperId = this.$route.query.examPaperId;
      this.isRouterAlive = new Date().getTime();
      this.setKey();
      this.$refs.ExamDetailsHeader.init(this.$route.query.examPaperId);
    },
    getNewTime() {
      this.refTime = new Date();
      this.isRouterAlive = new Date().getTime();
      // this.timer = setInterval(() => {
      //   this.markabnormolCount();
      // }, 25000);
    },
    goBack() {
      // console.log(this.$route.query.queryCode);
      let { routerName } = this.$route.query;
      if (routerName) {
        this.$router.push({
          name: routerName,
          query: { examId: this.examInformation.examId },
        });
      } else {
        if (!this.$route.query.queryCode) {
          this.$router.push({
            name: "examlist",
            query: { examId: this.examInformation.examId },
            // :url="'examlist'"
          });
        } else {
          let Base64 = require("js-base64").Base64;
          let queryCode = Base64.decode(this.$route.query.queryCode);
          this.$router.push({
            path: "/mark/new-mark-question",
            query: JSON.parse(queryCode),
          });
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.paperMonitor {
  padding: 24px 0 60px;

  .el-dropdown-link {
    cursor: pointer;
    color: $primary-color;
    font-size: 16px;
  }

  .header-box {
    background-color: #ffffff;
    padding: 24px 24px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .el-select {
      width: 112px;
      height: 32px;
    }

    .el-input {
      width: 192px;
    }

    .header-search-box {
      font-weight: 400;
      display: flex;
      flex-shrink: 0;
      flex-wrap: wrap;

      .search-item {
        font-size: 14px;
        margin-right: 24px;
        margin-bottom: 24px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .btn-list {
      margin-bottom: 24px;
    }

    .title {
      cursor: pointer;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 24px;
      color: #080a09;

      // width: 100%;
      .el-icon-arrow-left {
        cursor: pointer;
      }
    }
  }

  .monitor-box {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 24px;

    .views-box {
      width: calc(100% - 224px);
      background-color: #ffffff;
    }

    .tab-box {
      width: 200px;
      padding: 18px;
      background-color: #ffffff;
      .tab-item-box {
        background-color: #ffffff;
        border: 1px solid #dee0e7;
        border-radius: 4px;
        overflow: hidden;
        margin-bottom: 18px;
        .tab-item {
          padding: 12px 18px;
          color: #5a5a5a;
          cursor: pointer;
        }

        .tab-on {
          background: #f2faff;
          color: $primary-color;
          position: relative;

          &::after {
            width: 2px;
            height: 100%;
            background-color: $primary-color;
            position: absolute;
            left: 0;
            top: 0;
            content: "";
          }
        }

        .tab-err {
          color: #ff2b00;
        }

        .tab-item-header {
          border-bottom: 1px solid #e1e1e1;
          padding: 12px 18px;
          color: #161817;
          font-weight: 500;
          background: #f5f7fa;
        }
      }
    }
  }

  .allviewbox {
    .views-box {
      width: 100%;
    }

    .tab-box {
      display: none;
    }
  }

  .right-box {
    color: #080a09;
    margin-bottom: 24px;
    font-size: 16px;
    cursor: pointer;
  }

  .el-icon-refresh-right {
    color: $primary-color;
  }
}
</style>
