var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paperMonitor"},[_c('ExamDetailsHeader',{ref:"ExamDetailsHeader",attrs:{"router-url":_vm.$route.query.routerName ? _vm.$route.query.routerName : '/exam/unionExam',"query":{ examId: _vm.$route.query.examId }}}),_c('div',{staticClass:"monitor-box",class:{
      allviewbox:
        _vm.$route.query.type == 'teacher' || _vm.$route.query.from == 'examList',
    }},[_c('div',{staticClass:"tab-box"},[(_vm.addMonitorList.filter((item) => item.type).length > 0)?_c('div',{staticClass:"tab-item-box"},[_c('div',{staticClass:"tab-item-header"},[_vm._v("进度监控")]),_vm._l((_vm.addMonitorList),function(v,i){return [(v.type)?_c('div',{key:i,staticClass:"tab-item",class:{
              'tab-on':
                v.value == _vm.$route.name || v.value == _vm.$route.meta.fName,
            },on:{"click":function($event){return _vm.toLink(v)}}},[_vm._v(" "+_vm._s(v.name)+" ")]):_vm._e()]}),(_vm.userInfo.roleId != 5 || _vm.leaderType)?_c('div',{staticClass:"tab-item tab-err",class:{
            'tab-on':
              'unionMarkingTheAlarm' == _vm.$route.name ||
              _vm.$route.meta.fName == 'unionMarkingTheAlarm',
          },on:{"click":function($event){return _vm.toLink({
              value: 'unionMarkingTheAlarm',
            })}}},[_vm._v(" 阅卷报警（"+_vm._s(_vm.errNumber)+"） ")]):_vm._e()],2):_vm._e(),(_vm.qualityMonitorList.filter((item) => item.type).length > 0)?_c('div',{staticClass:"tab-item-box",staticStyle:{"margin-top":"8px"}},[_c('div',{staticClass:"tab-item-header"},[_vm._v("质量监控")]),_vm._l((_vm.qualityMonitorList),function(v,i){return [(v.type)?_c('div',{key:i,staticClass:"tab-item",class:{ 'tab-on': v.value == _vm.$route.name },on:{"click":function($event){return _vm.toLink(v)}}},[_vm._v(" "+_vm._s(v.name)+" ")]):_vm._e()]})],2):_vm._e()]),_c('div',{staticClass:"views-box"},[_c('router-view',{key:_vm.isRouterAlive})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }